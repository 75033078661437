<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Pengeluaran Kas</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Data Pengeluaran Kas</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Pengeluaran Kas"
          class="mr-2"
          @click="onAddData"
        />
      </div>
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column
            field="pg_no"
            header="no pengeluaran"
            style="min-width: 10rem"
            :showFilterMenu="false"
            sortable
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search"
                @keydown.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="pg_at"
            header="tanggal"
            style="min-width: 12rem"
            :showFilterMenu="false"
          >
            <template #body="{ data }">
              {{ formatDate(data.pg_at) }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <CalendarFilter
                ref="calendarFilter"
                v-model="filterModel.value"
                showButtonBar
                @select="filterCallback()"
              />
            </template>
          </Column>
          <Column
            field="keterangan"
            header="keterangan"
            style="min-width: 15rem"
            :showFilterMenu="false"
          >
          </Column>
          <Column
            field="total"
            header="total"
            style="min-width: 12rem"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ formatCurrency(slotProps.data.total || 0) }}
            </template>
          </Column>
          <Column
            field="status"
            header="status"
            style="min-width: 12rem"
            :showFilterMenu="false"
            sortable
          >
            <template #body="{ data }">
              <div :class="'status-badge status-' + data.status">
                {{ data.status }}
              </div>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown
                v-model="filterModel.value"
                @change="filterCallback()"
                :options="listStatus"
                optionValue="value"
                optionLabel="label"
                class="p-column-filter"
              >
              </Dropdown>
            </template>
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                :disabled="data.status != 'done'"
                type="button"
                icon="pi pi-print"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onPrint(data)"
              />
              <Button
                :disabled="data.status == 'done'"
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
              <Button
                :disabled="data.status == 'done'"
                type="button"
                icon="pi pi-trash"
                class="p-button-outlined p-button-danger p-button-text"
                @click="onConfirmDeletion(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit item' : 'Tambah item'"
      v-model:visible="dialog"
      class="p-overflow-hidden"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      modal
      maximizable
    >
      <form-pengeluaran
        :item="form"
        :loading="isLoadingSave"
        :list-pengeluaran="listPengeluaran"
        :list-status="listStatus"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Item"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ form.name }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItem"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import JenisPengeluaranService from '@/services/JenisPengeluaranService'
import PengeluaranService from '@/services/PengeluaranService'
import FormPengeluaran from '@/components/keuangan/FormPengeluaran'
import CalendarFilter from '@/components/CalendarFilter.vue'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import { formatCurrency, formatDate } from '@/helpers/index'
import { FilterMatchMode } from 'primevue/api'
import { saveAs } from 'file-saver'
import Hotkey from '@/components/Hotkey'

export default {
  name: 'Pengeluaran Kas',
  components: {
    FormPengeluaran,
    CalendarFilter,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      pengeluaranService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      listPengeluaran: [],
      listStatus: [
        { value: 'draft', label: 'draft' },
        { value: 'done', label: 'done' },
      ],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'pg_no',
        sortOrder: 'asc',
        filters: null,
      },
      filters: {
        pg_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pg_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      pdfSource: '',
      pdfFilename: '',
      pdfTitle: 'PDF Viewer ',
      dialogViewer: false,
    }
  },
  created() {
    this.pengeluaranService = new PengeluaranService()
  },
  async mounted() {
    const jenisPengeluaranService = new JenisPengeluaranService()
    await jenisPengeluaranService
      .get()
      .then((res) => {
        this.listPengeluaran = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Jenis Pengeluaran', this)
      })

    this.loadData()
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val)
    },
    formatDate(value) {
      return formatDate(value)
    },
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.pengeluaranService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Pengeluaran Kas', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.form = Object.assign({}, data)
      this.form = { ...this.form }
      this.dialog = true
    },
    onAddData() {
      this.form = {
        id: 0,
        pg_no: null,
        pg_at: null,
        jpng_id: 0,
        total: 0,
        status: 'draft',
      }
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      if (data.id === 0) {
        this.pengeluaranService
          .add(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Pengeluaran Kas',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Pengeluaran Kas', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.pengeluaranService
          .update(data.id, data)
          .then((res) => {
            if (res.data.status === 200) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Data Pengeluaran Kas',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.dialog = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Data Pengeluaran Kas', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteItem() {
      this.pengeluaranService
        .delete(this.form.id)
        .then((res) => {
          if (res.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Pengeluaran Kas',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Pengeluaran Kas', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onPrint(data) {
      this.pengeluaranService
        .print(data.id)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const pdfUrl = URL.createObjectURL(blob)
          this.pdfSource = pdfUrl + '#toolbar=0'
          this.pdfFilename = 'bukti-pengeluaran-kas.pdf'
          this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
          this.dialogViewer = true
        })
        .catch((err) => {
          errorHandler(err, 'Print Penerimaan Kas', this)
        })
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
}
</script>
