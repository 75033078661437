<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Keterangan</label>
              <InputText
                v-model="form.keterangan"
                :class="{
                  'p-invalid': v$.form.keterangan.$invalid && submitted,
                }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.keterangan.$invalid && submitted) ||
                  v$.form.keterangan.$pending.$response
                "
                class="p-error"
                >{{ v$.form.keterangan.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Jenis Pengeluaran</label>
              <Dropdown
                v-model="form.jpng_id"
                :filter="true"
                :options="listPengeluaran"
                class="w-full"
                :class="{
                  'p-invalid': v$.form.jpng_id.$invalid && submitted,
                }"
                input-class="w-full"
                optionValue="id"
                :optionLabel="jenisPengeluaranLabel"
                placeholder="Jenis Pengeluaran"
              >
              </Dropdown>
              <small
                v-if="
                  (v$.form.jpng_id.$invalid && submitted) ||
                  v$.form.jpng_id.$pending.$response
                "
                class="p-error"
              >
                {{ v$.form.coa_item_id.required.$message }}
              </small>
            </div>
            <div class="field col-12 md:col-12">
              <label>Total</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="item"
                  v-model="form.total"
                  class="w-full"
                  input-class="w-full"
                  placeholder="Rp 0"
                  locale="id-ID"
                  :min-fraction-digits="0"
                  :max-fraction-digits="0"
                  :allow-empty="false"
                  :class="{ 'p-invalid': v$.form.total.$invalid && submitted }"
                  @focus="selectAllText('item')"
                />
                <small
                  v-if="
                    (v$.form.total.$invalid && submitted) ||
                    v$.form.total.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.total.required.$message }}</small
                >
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <label>Status</label>
              <Dropdown
                v-model="form.status"
                :options="listStatus"
                optionValue="value"
                optionLabel="label"
                class="w-full"
                input-class="w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listPengeluaran: {
      type: Array,
      default() {
        return []
      },
    },
    listStatus: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    jenisPengeluaranLabel(e) {
      return e.kode + ' - ' + e.nama
    },
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        keterangan: {
          required: helpers.withMessage('keterangan harus diisi.', required),
        },
        jpng_id: {
          required: helpers.withMessage(
            'Jenis Pengeluaran harus diisi.',
            required
          ),
        },
        total: {
          required: helpers.withMessage('Total harus diisi.', required),
        },
        status: {
          required: helpers.withMessage('Head Akun harus diisi.', required),
        },
      },
    }
  },
}
</script>

